.root {
    display: inline-flex;

    & svg {
        fill: currentColor;
        overflow: visible;
    }

    & svg[fill="transparent"] {
        fill: transparent;
    }

    & path[fill="transparent"] {
        fill: transparent;
    }
}
