@import "style/mixins.module";

.root {
    padding: 100px 0;
    position: relative;
    text-align: center;

    @include respond-to("laptop") {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        min-height: 100vh;
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.logo {
    position: absolute;
    width: 140px;
    height: 28px;
    top: 24px;
    left: 0;

    @include respond-to("laptop") {
        width: 160px;
        height: 32px;
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.title {
    margin-bottom: 8px;

    @include respond-to("laptop") {
        margin-bottom: 16px;
    }
}

.description {
    margin-bottom: 64px;
    font-size: 17px;
    line-height: 24px;

    @include respond-to("laptop") {
        max-width: 480px;
        margin: 0 auto 80px;
        font-size: 22px;
        line-height: 32px;
    }
}

.contacts-list {
    list-style-type: none;
    padding-left: 0;
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;

    &__item {
        width: 100%;
        margin-right: 24px;
        border-bottom: 0.5px solid rgba(66, 78, 88, 0.1);

        @include respond-to("laptop") {
            border-bottom: none;
            width: auto;
        }
    }

    &__text {
        margin-bottom: 0;
    }

    &__icon {
        @include respond-to("laptop") {
            margin-right: 18px;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;

        @include respond-to("laptop") {
            padding-top: 0;
            padding-bottom: 0;
            flex-direction: row-reverse;
        }
    }

    @include respond-to("laptop") {
        max-width: 580px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }
}
