:root {
    --z-index-header: 100;
    --z-index-cookies-popup: 103;
    --z-index-cookies-banner: 102;
    --z-index-base-layout: 1001;
    --container-offset: calc((100vw - 1208px) / 2);
    --max-width-container-offset: calc((1600px - 1208px) / 2);
    --z-index-fixed-buttons: 99;
    --z-index-widget: 49;
    --z-index-modal: 104;
    --color-light-gray: #eef3f4;
    --divider-color: rgba(238, 243, 244, 0.4);
}
