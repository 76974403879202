.root {
    min-width: 320px;
    position: relative;
}

.size {
    &_default {
        width: 100%;
        margin: 0 auto;
        padding-right: 24px;
        padding-left: 24px;
    }

    &_full {
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
    }
}

@media (min-width: 768px) {
    .size {
        &_default {
            padding-right: 40px;
            padding-left: 40px;
        }
    }
}

@media (min-width: 1288px) {
    .size {
        &_default {
            max-width: 1288px;
        }
    }
}
