html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

a {
    text-decoration: none;
}

a:visited,
a:active,
a:-any-link:visited,
a:-webkit-any-link:visited,
a:-any-link:active,
a:-webkit-any-link:active {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
}

body {
    position: relative;
    height: 100vh;
    margin: 0;
    bottom: 0;

    min-width: 320px;
    color: rgba(38, 45, 51, 1);

    font-family: "Inter", sans-serif;
    font-size: 17px;
    line-height: 140%;
}

:global .hidden,
:global .mobile-hidden {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1;
}

h1 {
    line-height: 1em;
}

h2 {
    margin-bottom: 16px;
}

h3 {
    margin-bottom: 16px;
}

p {
    margin-bottom: 14px;
}

:global span.launcher-right-align button {
    height: 56px;
    width: 56px;
}


/* The presence of the s element is not announced by most screen reading technology in its default configuration. 
It can be made to be announced by using the CSS content property, along with the ::before and ::after pseudo-elements.
https://developer.mozilla.org/en-US/docs/Web/HTML/Element/s#accessibility_concerns */

s::before,
s::after {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

s::before {
  content: " [start of stricken text] ";
}

s::after {
  content: " [end of stricken text] ";
}

@media (min-width: 768px) {
    :global .mobile-hidden {
        overflow: auto;
    }
}

@media (min-width: 1366px) {
    body {
        font-size: 20px;
    }
}
