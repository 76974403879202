@import "style/mixins.module";

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
    padding-top: 24px;
    padding-bottom: 24px;
}

.logo {
    position: absolute;
    width: 140px;
    height: 28px;
    top: 24px;

    @include respond-to("laptop") {
        width: 160px;
        height: 32px;
    }

    svg {
        width: 100%;
        height: 100%;
    }
}