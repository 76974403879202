@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter"),
      url("/fonts/Inter/inter-regular.woff2") format("woff2"),
      url("/fonts/Inter/inter-regular.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local("Inter"),
      url("/fonts/Inter/inter-semi-bold.woff2") format("woff2"),
      url("/fonts/Inter/inter-semi-bold.woff") format("woff");
}